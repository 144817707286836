import { Icon } from '../../c-icon/class/icon'
import { ILabel } from '../interface/i-label'

/**
 * This class contains the properties of a label
 */
export class Label {
  constructor(props: ILabel) {
    this.icon = props.icon
    this.label = props.label
    this.counter = props.counter
    this.class = props.class
    this.iconOnRight = props.iconOnRight
  }
  /**
   * CIcon to be displayed
   */
  icon?: Icon
  /**
   * Text to be displayed on the label
   */
  label?: string[]
  /**
   * The number of items in the array passed to Label. Used to calculate icon to right of label
   * @todo For now this will always be one. But this will change in the future.
   */
  counter?: number
  /**
   * CSS to style the font size and theme colors etc
   */
  class?: string
  /**
   * Determines if the icon is to be displayed on the left or right
   */
  iconOnRight?: boolean
}
