import { render, staticRenderFns } from "./landing-page-banner-web.vue?vue&type=template&id=3e3aa35c&scoped=true"
import script from "./landing-page-banner-web.vue?vue&type=script&lang=ts"
export * from "./landing-page-banner-web.vue?vue&type=script&lang=ts"
import style0 from "./landing-page-banner-web.vue?vue&type=style&index=0&id=3e3aa35c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e3aa35c",
  null
  
)

export default component.exports