
import { defineComponent } from 'vue'
import { JobGTagEventFactory, StaticFileUrls } from '@tokyojob/frontend-core'
import { BannerSelectorMobile } from './enums/banner-selector-mobile.enum'
import { BannerText, LargeTextStyle, PeregreTextStyle } from './enums/banner-text.enum'
import { getProvidedApp, getProvidedGtag } from '~/base/utils/provided-getter'
export default defineComponent({
  setup(_, { emit }) {
    const gtag = getProvidedGtag()
    const methods = {
      setRestaurantTag() {
        const category = 'category'
        const restaurantCategoryId = 5
        const path = `${restaurantCategoryId}`
        gtag.track(JobGTagEventFactory.industryJobGridBannerClick(restaurantCategoryId))
        emit('select-item', path, category)
      }
    }
    const App = getProvidedApp()
    const language: keyof typeof BannerText = App.localizationSelector.language
    const textLine1 = BannerText[language][0]
    const textLine2 = BannerText[language][1]
    const textLine3 = BannerText[language][2]
    const textLine4 = BannerText[language][3]
    const buttonText = BannerText[language][4]
    const largeTextStyle = LargeTextStyle[language]
    const peregreTextStyle = PeregreTextStyle[language]
    const bannerImg = StaticFileUrls.restaurantAdBannerBgMobile
    const bannerEgg = StaticFileUrls[BannerSelectorMobile[language as keyof typeof BannerSelectorMobile]]

    return {
      bannerImg,
      bannerEgg,
      textLine1,
      textLine2,
      textLine3,
      textLine4,
      buttonText,
      largeTextStyle,
      peregreTextStyle,
      methods
    }
  }
})
