
import { defineComponent } from 'vue'
import { LocalizationPath } from '@tokyojob/core'
import { getLocalization } from '@tokyojob/frontend-components'
import { LocalizationService, StaticFileUrls } from '@tokyojob/frontend-core'
import { getProvidedApp } from '~/base/utils/provided-getter'
export default defineComponent({
  setup() {
    const localization = getLocalization(getProvidedApp())

    const banner = StaticFileUrls.tokyoJobBanner
    const jobsForText = LocalizationService.t<string>(localization, LocalizationPath.jobsForText)
    const jobsForClass = LocalizationService.t<string>(localization, LocalizationPath.jobsForClass)
    const foreignersText = LocalizationService.t<string>(localization, LocalizationPath.foreignersText)
    const foreignersClass = LocalizationService.t<string>(localization, LocalizationPath.foreignersClass)
    const inText = LocalizationService.t<string>(localization, LocalizationPath.inText)
    const inClass = LocalizationService.t<string>(localization, LocalizationPath.inClass)
    const japanText = LocalizationService.t<string>(localization, LocalizationPath.japanText)
    const japanClass = LocalizationService.t<string>(localization, LocalizationPath.japanClass)

    return {
      banner,
      jobsForText,
      jobsForClass,
      foreignersText,
      foreignersClass,
      inText,
      inClass,
      japanText,
      japanClass
    }
  }
})
