
import { defineComponent, PropType, computed } from 'vue'
import { Label } from './class/label'
import CIcon from '../c-icon/c-icon.component.vue'

export default defineComponent({
  name: 'c-label',
  components: {
    CIcon
  },
  props: {
    properties: {
      type: Object as PropType<Label>,
      required: true
    }
  },
  setup(props) {
    /**
     * Returns true if the icon is to be displayed on the left
     */
    const showLeftIcon = computed((): boolean => {
      return !!props.properties.icon && !props.properties.iconOnRight
    })
    /**
     * Returns true if the icon is to be displayed on the right
     */
    const showRightIcon = computed((): boolean => {
      return !!props.properties.icon && !!props.properties.iconOnRight
    })
    return {
      showLeftIcon,
      showRightIcon
    }
  }
})

