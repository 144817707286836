/**
 * List of available layouts to avoid hardcoding strings.
 */
export enum Layout {
  Home = 'home',
  App = 'app',
  Chat = 'chat',
  Auth = 'auth',
  SecurePage = 'secure-page'
}
