
import { computed, defineComponent, PropType } from 'vue'
import { IVacancy } from '@tokyojob/core'
import { CIconFactory, CVacancyCardMedium } from '@tokyojob/frontend-components'
import { IPaginateProps, VacancyHasAppliedComposable } from '@tokyojob/frontend-core'
import CPaginator from '../c-paginator/c-paginator.component.vue'
import { getRouter } from '~/base/utils/provided-getter'

export default defineComponent({
  components: { CVacancyCardMedium, CPaginator },
  props: {
    /**
     * The vacancies information used to populate VacancyItems.
     */
    vacancies: {
      type: Object as PropType<IPaginateProps<IVacancy>>,
      required: true
    }
  },
  setup(props, { emit }) {
    const router = getRouter()
    const jobsIcon = CIconFactory.Icon({
      icon: 'briefcase'
    })

    const pagination = computed(() => {
      return props.vacancies.meta
    })

    function setScrollPosition() {
      emit('setScrollPosition')
    }

    const { hasApplied } = VacancyHasAppliedComposable()

    return {
      // data properties
      jobsIcon,
      router,

      // computed
      pagination,

      // methods
      setScrollPosition,
      hasApplied
    }
  }
})
