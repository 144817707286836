import { LanguageURL } from "@tokyojob/core"

/**
 * Restaurant ad banner text.
 */
export const BannerText: Record<LanguageURL, string[]> = {
  [LanguageURL.EN]: ["Work in Japan's", 'Exciting', 'Restaurant Scene', 'Peregre Works Supports Your Success', 'Find Your Job Now'],
  [LanguageURL.JP]: ['人気の', '飲食店', 'で働こう！', 'あなたの成功を祈っています！', '今すぐ仕事を見つけよう'],
  [LanguageURL.VI]: ['Làm việc tại', 'nhà hàng', 'phổ biến!', 'Chúc bạn thành công!', 'Hãy tìm việc ngay bây giờ'],
  [LanguageURL.ZH]: ["受欢迎的", "餐饮店", "来工作吧！", "祝愿您的成功！", "立即找工作"]
}

export const LargeTextStyle: Record<LanguageURL, string> = {
  [LanguageURL.EN]: 'italic',
  [LanguageURL.JP]: 'text-700',
  [LanguageURL.VI]: 'italic',
  [LanguageURL.ZH]: 'text-700',
}

export const PeregreTextStyle: Record<LanguageURL, string> = {
  [LanguageURL.EN]: 'text-700 font-size-2 line-1-2',
  [LanguageURL.JP]: 'font-size-2',
  [LanguageURL.VI]: 'text-700 font-size-2 line-1-2',
  [LanguageURL.ZH]: 'font-size-2',
}
