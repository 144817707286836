import { IApp } from '@/types/nuxt'
import { IVacancy } from '@tokyojob/core'
import { errors, IPaginateProps, LogType, QueryStringEntity } from '@tokyojob/frontend-core'
import { getAppStore } from '~/store/app.store'

export default class FetchCompaniesUseCase implements BaseUsecase {
  app: IApp
  constructor(app: IApp) {
    this.app = app
  }

  // TODO: Refactor to use DtoVacancyList
  async execute(sessionId: string, isSSRRequest: boolean, options: QueryStringEntity): Promise<IPaginateProps<IVacancy>> {
    const { baseGateway, logService } = this.app
    const appStore = getAppStore()
    try {
      const data = await baseGateway.vacancy.Paginate(sessionId, isSSRRequest, options)
      return data
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      appStore.setErrors(errors(error))
      return {
        rows: [],
        meta: {}
      }
    }
  }
}
