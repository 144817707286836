
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'c-paginator-button',
  props: {
    /**
     * Page number to display in paginator
     */
    pageNumber: {
      type: Number,
      required: true
    },
    /**
     * If this button is the currently selected button in the paginator
     */
    isSelected: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const selectedClasses = computed(() => {
      return props.isSelected ? 'background-blue-light1 border-color-blue-dark1 disabled o-100' : 'theme-shades-light1-white pointer'
    })

    return {
      selectedClasses
    }
  }
})
