
import { CIcon, CIconFactory, Icon } from '@tokyojob/frontend-components'
import { IPaginateMeta } from '@tokyojob/frontend-core'
import { PropType, defineComponent, ref, computed, watch } from 'vue'
import { getRoute, getRouter } from '~/base/utils/provided-getter'
import CPaginatorButton from './components/c-paginator-button.component.vue'

const defaultPageNumber = '1'

export default defineComponent({
  components: {
    CIcon,
    CPaginatorButton
  },
  props: {
    paginate: {
      required: true,
      type: Object as PropType<IPaginateMeta>
    },
    /**
     * The icon to display on the left hand side of the
     * items counter text.
     */
    counterIcon: {
      required: false,
      type: Object as PropType<Icon>
    },
    /**
     * Text label to specify the type of data that is being paginated.
     * ie: jobs, workplaces, etc.
     */
    counterTypeLabel: {
      required: false,
      type: String,
      default: ''
    },
    /**
     * How many page buttons to show before and after the currently selected paginator page button
     */
    extraButtonCount: {
      type: Number,
      required: false,
      default: 1
    }
  },
  setup(props, { emit }) {
    const route = getRoute()
    const router = getRouter()

    const page = ref(Number(route.value.query?.page) || 1)
    const backIcon = CIconFactory.Icon({ icon: 'circle-arrow-left' })
    const forwardIcon = CIconFactory.Icon({ icon: 'circle-arrow-right' })
    const ellipsisIcon = CIconFactory.Icon({ icon: 'ellipsis' })

    /**
     * Update the current page in data, as well as the query string in the router
     */
    async function updatePageNumber(newPage: number): Promise<void> {
      page.value = newPage
      const query = { ...route.value.query, page: page.value.toString() }
      await router.replace({ query })
      setScrollPosition()
    }

    /**
     * Calculate new page number from the current page number, for use in buttons and keys
     */
    function calculateNewPageNumber(value: number): number {
      return page.value + value
    }

    /**
     * Hide additional buttons when reaching first possible page
     */
    function checkLowerPaginatorBound(value: number): boolean {
      return calculateNewPageNumber(value) < Number(defaultPageNumber)
    }

    /**
     * Hide additional buttons when reaching upper limit of total pages
     */
    function checkUpperPaginatorBound(value: number): boolean {
      return calculateNewPageNumber(value) > Number(props.paginate.totalPages)
    }

    /**
     * Disable left arrow when page 1
     */
    function disableLeftArrowButton(value: number): boolean {
      return value <= Number(defaultPageNumber)
    }

    /**
     * Disable right arrow when page limit reached.
     * Use >= incase no result are returned and totalPages is 0
     */
    function disableRightArrowButton(value: number): boolean {
      return value >= Number(props.paginate.totalPages)
    }

    /**
     * Set scroll position to top of vacancy cards
     */
    function setScrollPosition() {
      emit('setScrollPosition')
    }

    function checkMinPage(value: number): boolean {
      return page.value > Number(defaultPageNumber) + value
    }

    function checkMaxPage(value: number): boolean {
      // If we only do a < instead of <=, when we are on the first page, the last page will not be shown. ex: total pages === 3, but we only see 1 and 2 in the UI.
      return page.value <= Number(props.paginate.totalPages) - value
    }

    watch(
      () => route.value.query,
      (newQuery: Dictionary<string | (string | null)[]>) => {
        page.value = Number(newQuery.page) || 1
      }
    )

    return {
      // data properties
      page,
      backIcon,
      forwardIcon,
      ellipsisIcon,
      defaultPageNumber,

      // methods
      updatePageNumber,
      calculateNewPageNumber,
      checkLowerPaginatorBound,
      checkUpperPaginatorBound,
      disableLeftArrowButton,
      disableRightArrowButton,
      setScrollPosition,
      checkMinPage,
      checkMaxPage
    }
  },
  computed: {},
  methods: {}
})
