import { IconSize } from '../../c-icon/enum/icon-size.enum'
import { IconType } from '../../c-icon/enum/icon-type.enum'
import { Label } from '../class/label'
/**
 * Creates labels for the c-label component. Uses CIcon.
 * @todo seems like using static is a no-go, check proper implementation. https://refactoring.guru/design-patterns/factory-method
 */
export class LabelFactory {
  static new(props: Label) {
    return new Label({
      icon: props.icon,
      label: props.label,
      counter: props.counter,
      class: props.class || 'theme-shades-light1-color',
      iconOnRight: props.iconOnRight
    })
  }
  /**
   * Create the properties used by a locations c-label component.
   * @param props
   * @returns Label object
   * @example
   */
  static locations(location: string[], css: string): Label {
    const counter = location.length
    return new Label({
      icon: { icon: 'map-location-dot', type: IconType.duotone, size: IconSize.small },
      label: [location[0]],
      counter: counter - 1,
      class: css
    })
  }
  /**
   * Create the properties used by a wages and shifts c-label component.
   * @todo We will need to refactor as soon as we're getting multiple wages in an array
   */
  static wages(wages: string[], bonusText: string, css: string): Label {
    const counter = wages.length
    const label = []
    label.push(wages[0])
    if (bonusText) label.push(bonusText)
    return new Label({
      icon: { icon: 'yen-sign', type: IconType.duotone, size: IconSize.small },
      label: label,
      counter: counter,
      class: css
    })
  }

  /**
   * Creates a category label.
   * @param category
   * @param css
   * @returns
   */
  static category(category: string, css: string): Label {
    return new Label({
      icon: { icon: 'sitemap', type: IconType.solid, size: IconSize.small },
      label: [category],
      class: css
    })
  }

  static jpLevelSmall(jpLevel: string, css: string): Label {
    return new Label({
      icon: { svg: 'hiragana', size: IconSize.small, classes: 'w-16' },
      label: [jpLevel],
      class: css
    })
  }
}
